import React from "react";

export default function Main() {
  return (
    <>
      <div className="banner-section hero-section" id="home">
        <div className="container">
          <div className="content-wraper">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="left-content text-center">
                  {/* <h6 className="gameon">GAMEON
                  </h6> */}
                  <h1 className="texth1">
                  The Next Generation<br></br> Web 3.0 BBT Staking Platform
                  </h1>
                  <p className="text-white">
                  Full-scale Blockchain Gaming Ecosystem for BBT & Staking
                  </p>
                   {/* <div class="button">
                      <a href="#">Read More</a>
                      <a class="right" href="#">Contact Us</a>
                    </div>  */}
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
