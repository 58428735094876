import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import TableButtons from "../Table_Buttons/Table_Button";
import DashHeader from "../DashHeader/DashHeader";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";

const BBTHistory = () => {
  const [reportApi, setReportApi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(50);
  const dispatch = useDispatch();
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const referralAPI = async () => {
    try {
      let response = await API?.post("/BuytokenReuqestReport", {
        type:"4",
        uid: userId
      },
      {
        headers: {
          Authorization: jwtToken,
        },
      });
      
      console.log("response-->", response.data.data[0]);
      response = response.data.data[0];
      
      setReportApi([]);

      let arr = [];
      response.forEach((item, index) => {
        arr.push({
          sr: item?.RowNumber,
          uid: item?.uid,
          Edate1: item.Edate1,
          Usdtvalue:item.Usdtvalue,
          Usdthash: (
            <>
              <a href={`https://bscscan.com/tx/${item.Usdthash}`} target="_blank">View Txn</a>
            </>
          ),          
          Tokenvalue: ` ${item.Tokenvalue}`,
          Tokenhash: (
            <>
            {
              (item.Status =="Paid")?
              (<><a href={`https://explorer.skyhighblockchain.com/tx/${item.Tokenhash}`} target="_blank">View Txn</a>
              </>):
              (
                <>
                Request Pending
                </>
              )

            }
              
            </>
          ),
          Status:item.Status,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    referralAPI();
  }, []);

  const indexOfLastPost = currentPage * listPerPage;
  const indexOfFirstPage = indexOfLastPost - listPerPage;
  const currentPost = reportApi.slice(indexOfFirstPage, indexOfLastPost);

  const reportData = {
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "UserId", accessor: "uid" },
      { Header: "Date", accessor: "Edate1" },
      { Header: "USDT Amount", accessor: "Usdtvalue" },
      { Header: 'View Txn', accessor: 'Usdthash' },
      { Header: "Token amount", accessor: "Tokenvalue" },
      { Header: "View Txn", accessor: "Tokenhash" },
    ],
  };
  
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="container-fluid dashboardSection">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 h-100 d-flex align-items-center justify-content-center flex-column">
                  <h2 className="headingDashboard fw-bold py-3">
                    <span>BBT Buy History</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid dashboardSection2">
            <div className="container">
              <div className="row gy-4">
                <div className="col-md-12 mx-auto">
                  <div className="row mx-0  bottomtable ">
                    <div className="col-12 border rounded-2 py-3 backgroundcolor">
                      <div className="table table-responsive table-striped">
                        <Table
                          data={[...currentPost]}
                          columns={reportData.cols}
                        />
                        <TableButtons
                          indexOfFirstPage={indexOfFirstPage}
                          indexOfLastPost={indexOfLastPost}
                          setCurrentPage={setCurrentPage}
                          currentPage={currentPage}
                          totalData={reportApi.length}
                          listPerPage={listPerPage}
                          handleRowsPerPageChange={setListPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BBTHistory;
