import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const Privateroutes = () => {
  const { AinuAuth, jwtToken } = useSelector((state) => state.Auth);

  return (
    <>
      {AinuAuth === true && jwtToken.length > 20 ? (
        <Outlet />
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default Privateroutes;
