import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardData: {},
  packageDetails: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    fetchUserInfoSuccess: (state, action) => {
      state.dashboardData = action.payload;
    },
    fetchPackageData: (state, action) => {
      state.packageDetails = action.payload;
    },
  },
});

export const { fetchUserInfoSuccess, fetchPackageData } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
