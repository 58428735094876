import Web3 from "web3";
let isItConnected = false;

const networks = {
  skh: {
    chainId: `0x${Number(1476).toString(16)}`, // 1476 in hexadecimal
    chainName: "SKH smart chain",
    nativeCurrency: {
      name: "SKH",
      symbol: "SKH",
      decimals: 18,
    },
    rpcUrls: [
      "https://api.skyhighblockchain.com",  // Replace with actual SKH RPC URLs
      // "https://skh-rpc-url-2",
      // "https://skh-rpc-url-3",
    ],
    blockExplorerUrls: ["https://skhscan.com"],  // Replace with actual SKH block explorer URL
  },
};

const changeNetwork = async ({ networkName }) => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");

    let data = await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...networks[networkName],
        },
      ],
    });

    // alert('test',data)
  } catch (err) {
    console.log("Network not found", err);
  }
};

const handleNetworkSwitch = async (networkName) => {
  await changeNetwork({ networkName });
};

let accounts;

const getAccounts = async () => {
  const web3 = window.web3;
  try {
    accounts = await web3.eth.getAccounts();
    return accounts;
  } catch (error) {
    console.log("Error while fetching accounts: ", error);
    return null;
  }
};

export const disconnectWallet = async () => {
  await window.ethereum.request({
    method: "eth_requestAccounts",
    params: [{ eth_accounts: {} }],
  });
  console.log("Wallet disconnected");
};

export const loadWeb3 = async () => {
  try {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);

      await window.ethereum.enable();
      let ids = await window.web3.eth.getChainId();

      if (ids != "") {
        switch (ids.toString()) {
          case "1476":  // Chain ID for SKH chain
            isItConnected = true;
            break;
          default:
            handleNetworkSwitch("skh");
            isItConnected = false;
        }
      } else {
        handleNetworkSwitch("skh");
        isItConnected = false;
      }

      if (isItConnected == true) {
        let accounts = await getAccounts();
        return accounts[0];
      } else {
        let res = "Wrong Network";
        return res;
      }
    } else {
      let res = "No Wallet";
      return res;
    }
  } catch (error) {
    let res = "No Wallet";
    return res;
  }
};
