import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import storageSession from "redux-persist/lib/storage";
import AuthSlice from "./AuthSlice";
import DashboardSlice from "./DashboardSlice";
const persistConfig = {
  key: "root",
  // storage: storageSession,
  storage,
  version: -1,
};

const reducer = combineReducers({
  Auth: AuthSlice,
  Dashboarddata: DashboardSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
