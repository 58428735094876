import React, { useEffect, useState } from "react";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

export default function MyDirect() {
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
    //   { Header: "Sponser ID", accessor: "sid" },
      { Header: "User ID", accessor: "uid" },
      { Header: "Wallet", accessor: "wallet" },
      // { Header: "Coin Rate", accessor: "Coin_rate" },
      { Header: "Registration Date", accessor: "registrationDate" },
      { Header: "Status", accessor: "status" },
      { Header: "Activation Date", accessor: "dd" },
    ],
  });

  const DirectReport_API = async () => {
    try {
      let res = await API.post(
        `/myDirectReport`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          status: 2,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

    //   console.log("myDirect", res?.data?.data);
      let arr = [];
      res?.data?.data[0]?.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item?.uid,
        //   sid: item.sid,
          Coin_rate: item.Coin_rate,
          wallet: `${item.wallet?.substring(0, 6)}...${item.wallet?.substring(
            item.wallet.length - 4
          )}`,
          registrationDate: item?.registrationDate,
          status: item?.status,
          dd: item?.activationDate,
        });
      });

      // console.log("arr", arr);
      setreferralApi(arr);
    } catch (e) {
      console.log("Something Error DirectReport_API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            AinuAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    DirectReport_API();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="container-fluid pt-5 dashboardSection">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 h-100 d-flex align-items-center justify-content-center flex-column">
              {/* <a
              href="#"
              className="btn btn-dark dashboardbtn d-flex gap-2 px-3 py-2 bg-black"
            >
              Let's Connect On{" "}
              <span className="d-flex align-items-center justify-content-center">
                <img
                  className="w-100 h-100"
                  src="./assets/image/dashboard/x-twitter-white.png"
                  alt="logo"
                />
              </span>
            </a> */}
              <h2 className="headingDashboard fw-bold py-3">
                <span>My Direct</span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-lg-5 py-md-4 py-sm-3 py-2 dashboardSection2">
        <div className="container">
          <div className="row gy-4">
            <div className="col-md-12 mx-auto">
              <div className="row mx-0 mt-5 bottomtable ">
                <div className="col-12 border rounded-2 py-3 backgroundcolor">
                  <div className="table table-responsive table-striped">
                    <Table
                      data={[...currentPost]}
                      columns={matching_income.cols}
                    />
                    <Table_Buttons
                      setcurrentPage={setcurrentPage}
                      currentPage={currentPage}
                      totalData={referralApi.length}
                      listPerpage={listPerpage}
                      handleRowsPerPageChange={setlistPerpage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leftSideimage">
            <img
              className="w-100 h-100"
              src="https://wonderfx.info/assets/image/dashboard/ser_shap_l.png"
              alt=""
            />
          </div>
          <div className="rightSideimage">
            <img
              className="w-100 h-100"
              src="https://wonderfx.info/assets/image/dashboard/ser_shap_r.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
