import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AinuAuth: false,
  userId: null,
  jwtToken: null,
  ipAddress: null,
};

export const AuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.AinuAuth = action.payload.isAuth;
      state.userId = action.payload.userId;
      state.jwtToken = action.payload.jwtToken;
      state.ipAddress = action.payload.ipAddress;
    },
  },
});

export const { updateAuth, updateStatus } = AuthSlice.actions;
export default AuthSlice.reducer;
