import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <>
      <header className="header d-md-none d-lg-block d-sm-none">
        <div className="menu-box" id="sticky-header">
          <div className="container-fluid">
            <div className="menu-wraper">
              <div className="sticky-logo">
                <a href="/">
                  <img src="assets/image/logos.png" alt="" width="80px" />
                </a>
              </div>
              <div className="main-menu">
                <nav>
                  <ul>
                    {/* <li className="menu-item-has-children">
                      <a href="#home">Home</a>
                    </li>
                    <li>
                      <a href="#about">About</a>
                    </li> */}
                    {/* <li className="menu-item-has-children">
                      <a href="#service">Service</a>
                    </li> */}
                    {/* <li>
                      <a href="#token">Tokenomics</a>
                    </li> */}
                    {/* <li>
                      <a href="#roadmap">Roadmap</a>
                    </li> */}
                    {/* <li>
                      <a href="#faq">Faq</a>
                    </li> */}
                    {/* <li>
                      <a href="coming.jpg">Whitepaper</a>
                    </li> */}
                    {/* <li>
                      <a href="#contact">Contact</a>
                    </li> */}
                    {/* <li>
                      <Link to="/Login" onClick={closeMenu}>
                        Login
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/Register" onClick={closeMenu}>
                        Register
                      </Link>
                    </li> */}
                  </ul>
                </nav>
              </div>
              <div class="right-content">
					<div class="button">
						<a href="/login">Login</a>
					</div>
				</div> 
            </div>
          </div>
        </div>
      </header>

      <div
        className={`mobile-menu mean-container ${
          isMenuVisible ? "menu-visible" : ""
        }`}
      >
        <div className="mean-bar">
          <a
            href="#nav"
            className={`meanmenu-reveal ${isMenuVisible ? "meanclose" : ""}`}
            onClick={toggleMenu}
          >
            {isMenuVisible ? (
              <>
                <span></span>
                <span></span>
              </>
            ) : (
              <>
                <span></span>
                <span></span>
                <span></span>
              </>
            )}
          </a>
          <nav className="mean-nav">
            <ul style={{ display: isMenuVisible ? "block" : "none" }}>
              {/* Your menu items here */}
              <li className="menu-item-has-children">
                <a href="#home" onClick={closeMenu}>
                  Home
                </a>
              </li>
              <li>
                <a href="#about" onClick={closeMenu}>
                  About
                </a>
              </li>
              <li className="menu-item-has-children">
                <a href="#service" onClick={closeMenu}>
                  Service
                </a>
              </li>
              <li>
                <a href="#token" onClick={closeMenu}>
                  Tokenomics
                </a>
              </li>
              <li>
                <a href="#roadmap" onClick={closeMenu}>
                  Roadmap
                </a>
              </li>
              <li>
                <a href="#faq" onClick={closeMenu}>
                  Faq
                </a>
              </li>
              <li>
                <a href="coming.jpg">Whitepaper</a>
              </li>
              <li className="mean-last">
                <a href="#contact" onClick={closeMenu}>
                  Contact
                </a>
              </li>
              <li>
                      <Link to="/Login" onClick={closeMenu}>
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/Register" onClick={closeMenu}>
                        Register
                      </Link>
                    </li>
            </ul>
          </nav>
        </div>
        <div className="mean-push" />
        <nav className="main-menu" style={{ display: "none" }}>
          <ul>
            {/* Your menu items here */}
            <li className="menu-item-has-children">
              <a href="#home" onClick={closeMenu}>
                Home
              </a>
            </li>
            <li>
              <a href="#about" onClick={closeMenu}>
                About
              </a>
            </li>
            <li className="menu-item-has-children">
              <a href="#service" onClick={closeMenu}>
                Service
              </a>
            </li>
            <li>
              <a href="#token" onClick={closeMenu}>
                Tokenomics
              </a>
            </li>
            <li>
              <a href="#roadmap" onClick={closeMenu}>
                Roadmap
              </a>
            </li>
            <li>
              <a href="#faq" onClick={closeMenu}>
                Faq
              </a>
            </li>
            <li>
              <a href="coming.jpg">Whitepaper</a>
            </li>
            <li>
              <a href="#contact" onClick={closeMenu}>
                Contact
              </a>
            </li>
            <li>
                      <Link to="/Login" onClick={closeMenu}>
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/Register" onClick={closeMenu}>
                        Register
                      </Link>
                    </li>
            {/* <li>
              <Link to="/Login" onClick={closeMenu}>
                Login
              </Link>
            </li>
            <li>
              <Link to="/Register" onClick={closeMenu}>
                Register
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </>
  );
}
