import React, { useEffect, useState } from "react";
import "./DashMain.css";
import DashHeader from "../DashHeader/DashHeader";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import { fetchUserInfoSuccess } from "../../Redux/DashboardSlice";
import { updateAuth } from "../../Redux/AuthSlice";
import toast from "react-hot-toast";
import logo1 from "../Assets/logo1.png";

export const DashMain = () => {
  let Url = window.origin;
  const dispatch = useDispatch();
  const [dash, setdataArray] = useState([]);

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  async function DashboardAPI() {
    try {
      let responce = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      console.log("Dash res-->", responce);

      setdataArray(responce);
      dispatch(fetchUserInfoSuccess(responce));
    } catch (e) {
      console.log("Something Error", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            AinuAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  }
  useEffect(() => {
    DashboardAPI();
  }, []);

  function handleCopyClick() {
    let link = `${Url}/Register?sponserId=${userId}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
        toast.error("Failed to copy link!");
      });
  }
  return (
    <>
      <DashHeader />
      <div>
        <div className="container-fluid pt-3  dashboardSection">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 h-100 d-flex flex-column">
                <h2 className="headingDashboard fw-bold py-3">
                  <span>Dashboard</span>
                </h2>
                <div className="row justify-content-center">
                  <div className="col-md-2">
                  <a
                    href="/Buy"
                    className="magicbtn fw-bold px-4 py-3 text-white rounded-3"
                  >
                    <span>✨ Stake BBT ✨</span>
                  </a>
                  </div>
                 
                  <div className="col-md-2">
                  <a
                    href="/BuyBBT"
                    className="magicbtn fw-bold  px-4 py-3 text-white rounded-3"
                  >
                    <span>✨ Buy BBT ✨</span>
                  </a>
                  </div>
                  
                </div>
                <br />
                <div className="row">
                  <div className="col-md-3">
                  <div className="skh">
                    <div className="dashfl">
                    <div className="">
                      <a href="#"><span>USER ID : {userId}</span></a>
                      <br />
                      <a href="#">
                        <span className="d-flex align-items-center justify-content-center"></span>
                        <span>Total Deposit: {dash?.TotalInvest} BBT</span>
                      </a>
                    </div>
                    <div className="useft">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                  </div>

                  <div className="col-md-3">
                  <div className="magicbtn2 fw-bold px-4 py-3 text-white rounded-3">
                  <div className="dashfl">
                  <div className="">
                    <span> Current Cycle </span>
                    <br />
                    <span>{dash?.CurrentCycle}</span>
                    </div>
                    <div className="useft">
                    <i class="fa fa-snowflake-o" aria-hidden="true"></i>
                    </div>
                  </div>
                 
                  </div>
                  </div>
                  <div className="col-md-3">
                  <div className="magicbtn2 fw-bold px-4 py-3 text-white rounded-3">
                  <div className="dashfl">
                  <div className="">
                    <span>Cycle Limit</span>
                    <br />
                    <span>{dash?.CycleLimit} BBT</span>
                    </div>
                    <div className="useft">
                    <i class="fa fa-television" aria-hidden="true"></i>
                    </div>
                  </div>
                  </div>
                  </div>
                  
                  <div className="col-md-3">
                  <div
                    href="#"
                    className="magicbtn2 fw-bold  px-4 py-3 text-white rounded-3"
                  >
                    <div className="dashfl">
                    <div className="">
                    <span>Total Stake</span>
                    <br />
                    <span>{dash?.TotalStakeBBT} BBT</span>
                    </div>
                    <div className="useft">
                    <i class="fa fa-space-shuttle" aria-hidden="true"></i>
                    </div>
                  </div>
                  </div>
                  </div>
                  

                </div>
                
                <br />

               

                <div className="row">
                <div className="col-md-3">
                  <div className="magicbtn2 fw-bold  px-4 py-3 text-white rounded-3">
                  <div className="dashfl">
                   <div className="">
                    <span> Balance Stake </span>
                    <br />
                    <span>{dash?.RemainStakeBBT_1} BBT</span>
                    </div>
                    <div className="useft">
                    <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                    </div>
                  </div>
                  </div>
                  </div>
                </div>

                {/* <div className="row mx-0 py-4 w-100">
                  <div className="col-12">
                    <div className=" homecardround d-flex align-items-center justify-content-around  rounded-4 mb-3">
                      <a
                        href="LevelIncome"
                        className=" mb card border-0 rounded-5 text-decoration-none d-inline-block">
                        <div className="card-body">
                          <div className="row mx-0 align-items-center flex-lg-nowrap">
                            <div className="col-lg-auto">
                              <div className="cardleftimage">
                                <img
                                  className="w-100 h-100"
                                  src={logo1}
                                  alt="rocket1"
                                />
                              </div>
                            </div>
                            <div className="col-lg">
                              <div className="card-heading text-center">
                                {dash?.directincome}
                              </div>
                              <div className="card-text text-center">
                                Direct Income
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-lg-5 py-md-4 py-sm-3 py-2 dashboardSection2">
          <div className="container">
            <div className="leftSideimage">
              <img
                className="w-100 h-100"
                src="https://wonderfx.info/assets/image/dashboard/ser_shap_l.png"
                alt=""
              />
            </div>
            <div className="rightSideimage">
              <img
                className="w-100 h-100"
                src="https://wonderfx.info/assets/image/dashboard/ser_shap_r.png"
                alt=""
              />
            </div>
            <div className="row gy-2" hidden="hidden">
              <div className=" col-md-12 mx-auto">
                <div className="middleSection rounded-5 h-100 py-3 row mx-0">
                  <div className="col-12">
                    <div className="my-3 mx-3">
                      <h2 className="text-white fw-bold heading">
                        <span>&#x1F517;</span> Referral Address{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="copy-input mx-sm-3 position-relative bg-white rounded-pill">
                      <input
                        type="text"
                        className="form-control shadow-none border-0 py-3 px-1 rounded-pill"
                        defaultValue={`${Url}/Register?sponserId=${userId}`}
                      />
                      <div
                        className="copyimage border-start px-sm-3 position-absolute end-0 top-0 d-flex align-items-center h-100"
                        onClick={handleCopyClick}
                      >
                        <span className="d-flex align-items-center gap-2">
                          <img
                            className="w-100 h-100"
                            src="https://wonderfx.info/assets/image/dashboard/copy.svg"
                            alt="copy"
                          />
                        </span>{" "}
                        COPY{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
