import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashHeader from "../DashHeader/DashHeader";

const Withdrawal_History = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "Wallet", accessor: "wallet" },
      { Header: "Requested Amount Token", accessor: "Request_amount" },
      // { Header: "Deduction Charge", accessor: "Damount" },
      // { Header: "Withdrawal Share", accessor: "sidamount" },
      { Header: "Withdrawal Amount", accessor: "amountusd" },
      { Header: "Txn", accessor: "txn" },
      { Header: "Requested Date", accessor: "request_date" },
      { Header: "Status ", accessor: "ss" },
      // { Header: "social Media", accessor: "socialMedia_link1" },
      { Header: "Paid Date", accessor: "paid_date" },
    ],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const withdrawalHistory = async () => {
    try {
      let responce = await API?.post(
        `/withdrawalHistory`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("withdrawalHistory", responce);
      responce = responce?.data?.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,

          wallet: item.wallet
            ? item.wallet.substring(0, 4) +
              "..." +
              item.wallet.substring(item.wallet.length - 4)
            : null,
          request_date: item?.request_date,
          Request_amount: ` ${item?.tokenvalue}`,
          amountusd: `$ ${item?.amountusd}`,
          sidamount: `$ ${item?.sidamount}`,
          Damount: `$ ${item?.admincharge}`,
          socialMedia_link1: item.socialMedia_link1,
          ss: item?.ss,
          paid_date: item?.paid_date,
          amount: item?.amount,
          txn: (
            <a
              className="link-light"
              href={`https://bscscan.com/tx/${item?.txn}`}
              target="_blank"
              style={{ whiteSpace: "nowrap" }} rel="noreferrer">
              View Txn
            </a>
          ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            AinuAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    withdrawalHistory();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="container-fluid dashboardSection">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 h-100 d-flex align-items-center justify-content-center flex-column">
                  <h2 className="headingDashboard fw-bold py-3">
                    <span>Withdrawal History</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid  dashboardSection2">
            <div className="container">
              <div className="row gy-4">
                <div className="col-md-12 mx-auto">
                  <div className="row mx-0 bottomtable ">
                    <div className="col-12 border rounded-2 py-3 backgroundcolor">
                      <div className="table table-responsive table-striped">
                        <Table
                          data={[...currentPost]}
                          columns={matching_income.cols}
                        />
                        <Table_Buttons
                          setcurrentPage={setcurrentPage}
                          currentPage={currentPage}
                          totalData={referralApi.length}
                          listPerpage={listPerpage}
                          handleRowsPerPageChange={setlistPerpage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawal_History;
