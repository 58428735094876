import React from "react";
import Landing from "../Landing Page/Landing";

export default function Home() {
  return (
    <>
      <Landing />
    </>
  );
}
