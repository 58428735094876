import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import axios from "axios";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import { loadWeb3 } from "../../Utility/contract";
import "./Register.css";
export const Register = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("sponserId"));
  let navigate = useNavigate();
  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState("118844");
  const [LoginId, setLoginId] = useState();
  const [ipAddress, setIP] = useState("");

  const dispatch = useDispatch();

  async function IpAddressAPI() {
    try {
      let response = await axios.get("https://geolocation-db.com/json/");
      setIP(response.data.IPv4);
    } catch (e) {
      console.log("Error in IP API", e);
    }
  }

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);
        let res = await API.post(`/login`, {
          password: acc,
          ipaddress: ipAddress,
        });

        if (res.data.data.result === "Successfull") {
          toast.success(`Login Successful`);
          dispatch(
            updateAuth({
              isAuth: true,
              userId: res.data.data.uid_output,
              jwtToken: res.data.token,
              ipAddress: ipAddress,
            })
          );
          navigate("/Dashboard");
        } else {
          toast.error(res.data.data.result);
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();
    try {
      if (!acc) {
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        setconnected("Wrong Network");
      } else if (acc === "No Wallet") {
        toast.error(" Wrong Network Please Connect BSc Network");
        setconnected("Wrong Network");
      } else {
        try {
          if (sid === "" || sid === undefined) {
            toast.error("Enter Upline Id");
          } else {
            let res = await API.post("registration", {
              sid: sid,
              accountnumber: acc,
              position: "1",
              uid: 0,
              addresslist: 0,
              amountList: "dsfdsf",
              paymentType: "Card",
              status: 1,
              result: "",
              outputuid: 0,
            }); 
            console.log("registration", res.data);
            if (res.data.data.result === "Successfull") {
              toast.success("Register Successfull");
              let res = await API.post(`/login`, {
                password: acc,
                ipaddress: ipAddress,
              });

              if (res.data.data.result === "Successfull") {
                toast.success(`Login Successful`);
                dispatch(
                  updateAuth({
                    isAuth: true,
                    userId: res.data.data.uid_output,
                    jwtToken: res.data.token,
                    ipAddress: ipAddress,
                  })
                );
                navigate("/DashBoard");
              } else {
                toast.error(`Invalid User id or Address`);
              }
            } else {
              toast.error(`${res.data.data.result}`);
            }
          }
        } catch (e) {
          console.log("Error while regiater", e);
        }
      }
    } catch (e) {
      console.log("eroor Login API", e);
    }
  };

  useEffect(() => {
    IpAddressAPI();
    walletConnected();
  }, []);

  function handleSid(e) {
    const inputText = e.target.value.replace(/[^0-9]/gi, "").substring(0, 8);
    const sanitizedInput = DOMPurify.sanitize(inputText);
    setSid(sanitizedInput);
  }
  return (
    <div>
      <div className="Login_bg">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3" />
              <div className="col-md-6">
                <div id="root">
                  <div id="reg-layout">
                    <div className="Toastify" />
                    <div className="reg-box">
                      <center style={{ marginBottom: 20, marginTop: "1rem" }} />
                      <a href="/">
                        <div className="reg-label" />
                      </a>
                      <div className="reg-content">
                        <div className="space_set" />
                        <div className="subtitle">
                          Access your personal account
                        </div>
                        <span id="lock" style={{ display: "none" }} />
                        <span id="network" style={{ display: "none" }}>
                          This is the unknown test network.
                        </span>
                        <span
                          id="metamaskConnection"
                          className="metamaskConnection"
                          style={{ color: "green" }}
                        >
                          {connected}
                        </span>
                        <button
                          className="btn btn-primary set_z_index"
                          // onClick={() => setregistor_Model(true)}
                          onClick={() => {
                            registerUser();
                          }}
                        >
                          Register
                        </button>
                        <p className="note">
                          Remember to authorize with the correct Wallet address.
                        </p>
                        <div className="space_set" />
                        <div className="subtitle">View an Account</div>
                        <br />
                        <form>
                          <div className="form-row">
                            <input
                              id="loginid"
                              type="text"
                              maxLength={100}
                              placeholder="Please enter ID or address "
                              className="form-input"
                              value={LoginId}
                              readOnly
                            />
                            <p className="note">
                              Enter the account id or address
                            </p>
                          </div>
                          <div
                            className="form-row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              type="button"
                              className="btn btn-secondary"
                              id="btnlogins"
                              defaultValue="Connect to Wallet"
                            />
                          </div>
                        </form>
                        <p className="loginagain">
                          If you have already registered{" "}
                          <Link
                            to="/Login"
                            style={{ color: "rgb(255, 255, 255)" }}
                          >
                            Login
                          </Link>
                        </p>
                        <div className="footer-section2">
                          Copyright © 2024 stakebbt . All Rights
                          Reserved.&nbsp;&nbsp;&nbsp;
                        </div>
                        <div
                          id="myModal"
                          className="modal"
                          style={{ display: registor_Model ? "block" : "none" }}
                        >
                          <div className="modal-content modal-contentlogin boxset">
                            <h4>Enter Upline ID</h4>
                            <br />
                            <p>
                              {" "}
                              <input
                                id="txtsid"
                                className="input-id"
                                maxLength={8}
                                type="number"
                                value={sid}
                                placeholder="Enter Upline Id"
                                onChange={(e) => {
                                  handleSid(e);
                                }}
                              />
                            </p>
                            <div className="rerlbtn">
                              <button
                                className=" btn_Model"
                                onClick={() => {
                                  registerUser();
                                }}
                              >
                                Register
                              </button>
                              <button
                                className="mx-2 btn_Model"
                                onClick={() => setregistor_Model(false)}
                              >
                                close
                              </button>
                            </div>
                          </div>
                        </div>
                        <div id="myModal123" className="modal1 d-none">
                          <div className="modal-content boxset">
                            <h4>Referral Confirmation</h4>
                            <p>
                              Your Current Referral ID is <label>100</label>
                            </p>
                            <br />
                            <br />
                            <div className="rerlbtn">
                              <button className="btn btn-primary">
                                Proceed
                              </button>
                              <a
                                href="/registration"
                                className="btn btn-primary"
                              >
                                No,I want to change ID
                              </a>
                            </div>
                          </div>
                        </div>
                        <center>
                          <br />
                          <a
                            href="/"
                            title="Go To Home"
                            id="gotohome"
                            style={{ color: "rgb(255, 255, 255)" }}
                          >
                            Go To Home
                          </a>
                        </center>
                      </div>
                    </div>
                    <input
                      type="hidden"
                      id="refaddress"
                      defaultValue="0xFfE7deE80672e14E61e8DE2Eb6CFa450Efc78aaB"
                    />
                    <input
                      type="hidden"
                      id="idad3"
                      defaultValue="Sponsor,Business,Fee"
                    />
                    <input
                      type="hidden"
                      id="HiddenFieldSID"
                      className="HiddenFieldSID"
                      defaultValue={100}
                    />
                    <input type="hidden" id="Hidden1txn1" />
                    <input type="hidden" id="Hidden2txn2" />
                  </div>
                </div>
              </div>
              <div className="col-md-7" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
